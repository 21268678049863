.nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-item {
  width: 100%;
}
